const batteryStateList = [{
  title: '搁置',
  value: '0',
  children: []
},
{
  title: '充电中',
  value: '1',
  children: []
},
{
  title: '放电中',
  value: '2',
  children: []
},
{
  title: '休眠',
  value: '3',
  children: []
},
{
  title: '掉电',
  value: '4',
  children: []
},
{
  title: '移动(V2.0)',
  value: '5',
  children: [{
    title: '放电状态',
    value: '50',
    isLeaf: true
  },
  {
    title: '充电状态',
    value: '51',
    isLeaf: true
  },
  {
    title: '负载在位状态',
    value: '52',
    isLeaf: true
  },
  {
    title: '充电在位状态',
    value: '53',
    isLeaf: true
  },
  {
    title: '空载状态',
    value: '54',
    isLeaf: true
  }]
},
{
  title: '静止(V2.0)',
  value: '6',
  children: [{
    title: '放电状态',
    value: '60',
    isLeaf: true
  },
  {
    title: '充电状态',
    value: '61',
    isLeaf: true
  },
  {
    title: '负载在位状态',
    value: '62',
    isLeaf: true
  },
  {
    title: '充电在位状态',
    value: '63',
    isLeaf: true
  },
  {
    title: '空载状态',
    value: '64',
    isLeaf: true
  }]
},
{
  title: '存储(V2.0)',
  value: '7',
  children: [{
    title: '放电状态',
    value: '70',
    isLeaf: true
  },
  {
    title: '充电状态',
    value: '71',
    isLeaf: true
  },
  {
    title: '负载在位状态',
    value: '72',
    isLeaf: true
  },
  {
    title: '充电在位状态',
    value: '73',
    isLeaf: true
  },
  {
    title: '空载状态',
    value: '74',
    isLeaf: true
  }]
}]
export default {
  batteryStateList: batteryStateList
}

<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="设备编号:">
              <a-input
                v-model:value.trim="where.batteryImei"
                placeholder="请输入设备编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池编号:">
              <a-input
                v-model:value.trim="where.batteryCode"
                placeholder="请输入电池编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operatorId">
              <a-select
                v-model:value="where.operatorId"
                placeholder="请选择所属运营商"
              >
                <a-select-option
                  v-for="(item, index) in operatorList"
                  :key="index"
                  :value="item.operatorId"
                  >{{ item.operatorName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="pointId">
              <a-select
                v-model:value="where.pointId"
                placeholder="请选择所属租赁点"
              >
                <a-select-option
                  :value="item.pointId"
                  v-for="(item, index) in pointList"
                  :key="index"
                  >{{ item.pointName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池品牌:" name="brand">
              <a-select
                v-model:value="where.brand"
                placeholder="请选择电池品牌"
              >
                <a-select-option
                  v-for="(item, index) in brandList"
                  :key="index"
                  :value="item.brandName"
                >
                  {{ item.brandName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="型号:">
              <a-select v-model:value="where.model" placeholder="请选择型号">
                <a-select-option
                  v-for="(item, index) in modelList"
                  :key="index"
                  :value="item.model"
                >
                  {{ item.model }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="备注:">
              <a-input
                v-model:value.trim="where.remark"
                placeholder="请输入备注"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池协议" name="batteryProtocol">
              <a-select
                v-model:value="where.batteryProtocol"
                placeholder="请选择电池协议"
              >
                <a-select-option
                  :value="item.dictDataName"
                  v-for="item in batteryProtocolList"
                  :key="item.dictDataCode"
                  >{{ item.dictDataName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="业务状态" name="serviceState">
              <a-select
                v-model:value="where.serviceState"
                placeholder="请选择业务状态"
              >
                <a-select-option
                  :value="item.dictDataCode"
                  v-for="item in serviceStateList"
                  :key="item.dictDataCode"
                  >{{ item.dictDataName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="在线状态" name="onLineState">
              <a-select
                v-model:value="where.onLineState"
                placeholder="请选择在线状态"
              >
                <a-select-option
                  :value="item.dictDataCode"
                  v-for="item in onlineStateList"
                  :key="item.dictDataCode"
                  >{{ item.dictDataName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="定位状态" name="positionState">
              <a-select
                v-model:value="where.positionState"
                placeholder="请选择定位状态"
              >
                <a-select-option
                  :value="item.dictDataCode"
                  :key="item.dictDataCode"
                  v-for="item in positionStateList"
                  >{{ item.dictDataName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池状态" name="batteryState">
              <!-- <a-select
                v-model:value="where.batteryState"
                placeholder="请选择电池状态"
              >
                <a-select-option :value="item.value" v-for="item in batteryStateList" :key='item.value'>{{item.label}}</a-select-option>
              </a-select> -->
              <a-tree-select
                v-model:value="where.batteryState"
                allow-clear
                placeholder="请选择电池状态"
                :maxTagCount="2"
                treeCheckable
                :show-checked-strategy="SHOW_ALL"
                :tree-data="batteryStateList"
                @select="changeBatteryState"
              >
              </a-tree-select> </a-form-item
          ></a-col>
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属省份" name="provice">
              <a-select
                v-model:value="where.provice"
                placeholder="请选择所属省份"
                @change="changeProvice"
              >
                <a-select-option
                  :value="item.value"
                  v-for="item in cityData"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属城市" name="city">
              <a-select
                v-model:value="where.city"
                placeholder="请选择所属城市"
                mode="multiple"
                :showArrow="true"
              >
                <a-select-option
                  :value="item.value"
                  v-for="item in cityList"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="batteryId"
        :datasource="datasource"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
      >
        <!-- <template #pointName="{ record }">
          <router-link :to="'/point/detail?id=' + record.pointId">
            {{ record.pointName }}
          </router-link>
        </template> -->
        <template #state="{ record }">
          <span>
            {{
              record.state === 'using'
                ? '租赁中'
                : record.state === 'stock'
                ? '库存'
                : record.state === 'repair'
                ? '维修中'
                : ''
            }}
          </span>
        </template>
        <template #serviceState="{ record }">
          <div>
            {{
              record.serviceState === 'warehouseStock'
                ? '仓库库存'
                : record.serviceState === 'siteInventory'
                ? '站点库存'
                : record.serviceState === 'inTheLease'
                ? '租赁中'
                : record.serviceState === 'inMaintenance'
                ? '维修中'
                : record.serviceState === 'scrap'
                ? '报废'
                : record.serviceState === 'sold'?'已销售':''
            }}
          </div>
        </template>
        <template #batteryState="{ record }">
          <div>
            {{
              record.serviceState === 'warehouseStock'
                ? '仓库库存'
                : record.serviceState === 'siteInventory'
                ? '站点库存'
                : record.serviceState === 'inTheLease'
                ? '租赁中'
                : record.serviceState === 'inMaintenance'
                ? '维修中'
                : record.serviceState === 'scrap'
                ? '报废'
                : record.serviceState === 'sold'?'已销售':''
            }}
          </div>
        </template>
        <template #onlineState="{ record }">
          <div>
            {{
              record.onlineState === '1'
                ? '在线'
                : record.onlineState === '0'
                ? '离线'
                : ''
            }}
          </div>
        </template>
        <template #toolbar>
        </template>
        <template #action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              更多操作
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu @click="moreOnClick">
                <router-link :to="{path:'/zulin/assets/battery/battery-monitor',query:{deviceId:record.batteryCode}}">
                  <a-menu-item @click="option(record)" key="监控"
                    >监控</a-menu-item
                  >
                </router-link>
                <a-menu-item key="备注" @click="addRemark(record)"
                  >备注</a-menu-item
                >
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </ud-pro-table>
    </a-card>
    <a-modal
      v-model:visible="visibleRemark"
      title="备注"
      @ok="handleRemarkOk"
      centered
    >
      <a-form>
        <a-form-item label="备注">
          <a-textarea
            v-model:value="customerForm.remark"
            placeholder="请输入备注"
          >
          </a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visibleLog"
      title="操作日志"
      :width="800"
      centered
      :footer="null"
    >
      <ud-pro-table
        ref="logs"
        class="logForm"
        row-key="createTime"
        :datasource="logData"
        :columns="logColumns"
        :scroll="{ x: 'max-content' }"
      >
      </ud-pro-table>
    </a-modal>
    <a-modal
      v-model:visible="visibleCode"
      title="二维码"
      :width="300"
      :footer="null"
      centered
    >
      <a-image :width="250" :height="250" src="/static/code.png" />
      <div class="downLoad">
        <span> 下载 </span>
      </div>
    </a-modal>
    <!-- <brand-detail v-model:visible="monitor" :data="current || {}" /> -->
  </div>
</template>
<script>
import * as batteryApi from '@/api/rnt/battery'
import { defineComponent } from 'vue'
import { DownOutlined } from '@ant-design/icons-vue'
import * as batteryModelApi from '@/api/rnt/batteryModel'
import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point'
import regions from '@/utils/regions'
import battery from '@/utils/batteryState'
import { TreeSelect } from 'ant-design-vue'
// import brandDetail from './battery-monitor.vue'
export default defineComponent({
  name: 'batteryList',
  components: {
    DownOutlined
  },
  data() {
    return {
      SHOW_ALL: TreeSelect.SHOW_ALL,
      cityData: regions.cityData,
      cityList: [],
      batteryStateList: battery.batteryStateList,
      expand: false,
      customerForm: {},
      visibleRemark: false,
      visibleLog: false,
      visibleCode: false,
      pagination: {
        total: 0,
        defaultPageSize: 10,
        showTotal: (total) => `共 ${total} 条数据`,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        onShowSizeChange: (current, pageSize) => (this.pageSize = pageSize)
      },
      // logData: [
      //   {
      //     actionContent: '充值金额380.0元',
      //     actionPerson: 'guowenjun',
      //     actionTime: '2022-01-12 15:46:38'
      //   },
      //   {
      //     actionContent: '充值押金4000.0元',
      //     actionPerson: 'guowenjun',
      //     actionTime: '2022-01-12 15:46:38'
      //   },
      //   {
      //     actionContent: '充值金额380.0元',
      //     actionPerson: 'guowenjun',
      //     actionTime: '2022-01-12 15:46:38'
      //   },
      //   {
      //     actionContent: '充值金额380.0元',
      //     actionPerson: 'guowenjun',
      //     actionTime: '2022-01-12 15:46:38'
      //   }
      // ],
      logColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 65,
          align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '操作内容',
          align: 'center',
          dataIndex: 'actionContent'
        },
        {
          title: '操作人',
          align: 'center',
          dataIndex: 'actionPerson'
        },
        {
          title: '操作时间',
          align: 'center',
          width: 200,
          dataIndex: 'createTime'
        }
      ],
      name: 'batterytList',
      // 表格列配置
      columns: [
        {
          title: '编号',
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '电池编号',
          dataIndex: 'batteryCode',
          sorter: true
        },
        {
          title: '电池品牌',
          dataIndex: 'brand',
          sorter: true
        },
        {
          title: '设备编号',
          dataIndex: 'batteryImei',
          sorter: true
        },
        {
          title: '型号',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '电池协议',
          dataIndex: 'protocolType',
          sorter: true
          // slots: { customRender: 'protocolType' }
        },
        {
          title: '电池状态',
          // dataIndex: 'onlineState',
          slots: {
            customRender: 'state'
          },
          sorter: true
        },
        {
          title: '电量SOC',
          dataIndex: 'batterySoc',
          sorter: true
        },
        {
          title: '业务状态',
          slots: {
            customRender: 'serviceState'
          },
          sorter: true
        },
        {
          title: '电池状态',
          slots: {
            customRender: 'batteryState'
          },
          sorter: true
        },
        {
          title: '在线状态',
          slots: {
            customRender: 'onlineState'
          },
          sorter: true
        },
        {
          title: '运营商名称',
          dataIndex: 'operatorName',
          sorter: true
        },
        {
          title: '定位状态',
          dataIndex: 'positionState',
          sorter: true
          // slots: { customRender: 'pointName' }
        },
        {
          title: '所属省份',
          dataIndex: 'provice',
          sorter: true
        },
        {
          title: '所属城市',
          dataIndex: 'city',
          sorter: true
        },
        {
          title: '数据时间',
          dataIndex: 'bmsDateTime',
          sorter: true
        },
        {
          title: '库存时间(天)',
          dataIndex: 'repertoryTime',
          sorter: true
        },
        {
          title: '所属租赁点名称',
          dataIndex: 'pointName',
          sorter: true
          // slots: { customRender: 'pointName' }
        },
        {
          title: '仓库名称',
          dataIndex: 'warehouseName',
          sorter: true
        },
        {
          title: '备注',
          dataIndex: 'remark',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 是否显示监控弹窗
      monitor: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      operatorList: [],
      brandList: [],
      modelList: [],
      pointList: [],
      batteryProtocolList: [],
      serviceStateList: [],
      onlineStateList: [],
      positionStateList: []
    }
  },
  created() {
    this.queryOperator()
    this.queryBatteryBrand()
    this.queryBatteryModel()
    this.queryPoint()
    this.queryBatteryProtocol()
    this.queryServiceState()
    this.queryOnlineState()
    this.queryPositionState()
  },
  methods: {
    // 电池状态改变监听
    changeBatteryState(value, label, extra) {
      console.log(value, label, extra)
      if (extra.checkedNodes.length > 1) {
        extra.checkedNodes.shift()
      }
    },
    // 所属省份改变监听
    changeProvice(value) {
      const data = regions.getChildrenLabel(String(value))
      this.cityList = data
    },
    // 查询所有电池协议
    queryBatteryProtocol() {
      batteryApi
        .batteryDictdata()
        .then((res) => {
          if (res.code === 0) {
            this.batteryProtocolList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有业务状态
    queryServiceState() {
      batteryApi
        .dictData({ dictCode: 'serviceState' })
        .then((res) => {
          if (res.code === 0) {
            this.serviceStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有在线状态
    queryOnlineState() {
      batteryApi
        .dictData({ dictCode: 'onlineState' })
        .then((res) => {
          if (res.code === 0) {
            this.onlineStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有定位状态
    queryPositionState() {
      batteryApi
        .dictData({ dictCode: 'positionState' })
        .then((res) => {
          if (res.code === 0) {
            this.positionStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有租赁点
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有运营商
    queryOperator() {
      operatorApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有电池品牌
    queryBatteryBrand() {
      batteryModelApi
        .brand()
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有电池型号
    queryBatteryModel() {
      batteryModelApi
        .model()
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    lookCode(record) {
      this.visibleCode = true
      this.customerForm.batteryCode = record.batteryCode
    },
    // 查看操作日志
    checkLog(record) {
      this.visibleLog = true
      this.customerForm.batteryCode = record.batteryCode
      this.$refs.logs.reload({ page: 1 })
    },
    addRemark(record) {
      this.visibleRemark = true
      this.customerForm.batteryCode = record.batteryCode
    },
    handleRemarkOk() {
      batteryApi
        .save(this.customerForm, true)
        .then((res) => {
          if (res.code === 0) {
            this.visibleRemark = false
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 详情 */
    option(row) {
      this.current = row
      this.monitor = true
    },
    moreOnClick(key) {
      console.log(key)
    },
    logData(option, callback) {
      batteryApi
        .page({
          sort: 'createTime',
          order: 'desc',
          page: option.page,
          limit: option.limit,
          ...this.customerForm
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    datasource(option, callback) {
      batteryApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.cityList = []
      this.$nextTick(() => {
        this.reload()
      })
    }
  }
})
</script>

<style scoped lang='less'>
.downLoad {
  margin-top: 20px;
  span {
    color: #1890ff;
    border: 1px solid #1890ff;
    padding: 5px 20px;
    background-color: #f3f7f8;
    border-radius: 50px;
  }
}
.form {
  background-color: #fff;
  .btn-wrap {
    .iconfont {
      margin-right: 8px;
    }
  }
}
.logForm {
  :deep(.ud-table-tool) {
    display: none;
  }
}
</style>
